import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Vimeo from "@u-wave/react-vimeo"
import { Player } from "@vimeo/player"

import { MOBILE_BREAKPOINT } from "@util/constants"

const VideoWrapper = styled.div<{ hideInMobile: boolean | undefined }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ hideInMobile }) => hideInMobile && `display: none;`}
  }
`

const StyledVimeo = styled(Vimeo)<{
  foregroundOpen?: boolean
  minHeight?: string
  minWidth?: string
}>`
  iframe {
    width: ${props => props.width ?? "100vw"};
    height: ${props => props.height ?? "56.25vw"};
    min-height: ${props => props.minHeight ?? "100%"};
    min-width: ${props => props.minWidth ?? `100%;`};
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ foregroundOpen }) => foregroundOpen && `min-width: 0px`}
  }
`

interface Props {
  url: string
  volume?: boolean
  height?: string
  width?: string
  minWidth?: string
  minHeight?: string
  foregroundOpen?: boolean
  autoPlayOnLoad?: boolean
  isVisible?: boolean
  background?: boolean
  controls?: boolean
  hideInMobile?: boolean
}

function Video(props: Props) {
  const {
    url,
    volume,
    height,
    width,
    minHeight,
    minWidth,
    foregroundOpen,
    autoPlayOnLoad,
    isVisible,
    background,
    controls,
    hideInMobile,
  } = props

  const [autoPlay, setAutoplay] = useState(autoPlayOnLoad)
  const playerRef = useRef<Player>()

  function onLoad() {
    if (autoPlay && isVisible) {
      playerRef.current && playerRef.current.play()
    }
  }

  function onReady(player: Player) {
    playerRef.current = player
  }

  useEffect(() => {
    if (isVisible) {
      playerRef.current ? playerRef.current.play() : setAutoplay(true)
    }

    if (!isVisible && playerRef.current) {
      playerRef.current.getPlayed().then(played => {
        if (played.length == 0) {
          return
        }

        if (played.length > 0) {
          setTimeout(() => {
            playerRef.current && playerRef.current.pause()
          }, 1000)
        }
      })
    }
  }, [isVisible])

  useEffect(() => {
    if (playerRef.current && foregroundOpen) {
      playerRef.current.setCurrentTime(0)
    }
  }, [foregroundOpen])

  return (
    <VideoWrapper hideInMobile={hideInMobile}>
      <StyledVimeo
        loop
        video={url}
        autoplay={false}
        background={background}
        muted={!volume}
        height={height}
        width={width}
        minHeight={minHeight}
        minWidth={minWidth}
        volume={volume ? 1 : 0}
        controls={controls ? true : false}
        onLoaded={onLoad}
        onReady={onReady}
        foregroundOpen={foregroundOpen}
        showByline={false}
      />
    </VideoWrapper>
  )
}

export default Video
