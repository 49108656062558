import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import MyLink from "../myLink"
import { LocationsContext } from "../../contexts/locationsContext"
import "./integrationsFeed.sass"

const IntegrationsFeed = () => {
  const {
    sanityIntegrationsPage: { integrations: integrationsData },
    sanityProductsPage: { integrationsFeed },
  } = useStaticQuery(graphql`
    {
      sanityIntegrationsPage {
        integrations {
          _key
          title
          image {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          loactions {
            locations {
              countryCode
            }
          }
        }
      }
      sanityProductsPage {
        integrationsFeed {
          title
          button {
            url
            newwindow
            linktext
            internallink
          }
        }
      }
    }
  `)

  const { currentLocation } = useContext(LocationsContext)
  const [integrations, setIntegrations] = useState(integrationsData)

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(integrationsData)
    } else {
      const filteredData = integrationsData.filter(
        integrations =>
          integrations.loactions && // prevent error, if it's null, return false
          integrations.loactions.locations
            .map(location => location.countryCode) // reconstruct country code as array of string
            .includes(currentLocation.countryCode) // chech if the country code same as current country code, then return true
      )
      setIntegrations(filteredData)
    }
  }, [currentLocation])

  // if there are no integration in the region
  if (integrations.length === 0) return <div></div>

  return (
    <div className="integrationsFeed">
      <div className="container">
        <h3>{integrationsFeed.title}</h3>
        <div className="integrations">
          {integrations.slice(0, 18).map(integration => (
            <Link
              key={integration._key}
              to={`/integrations/${integration.title
                .toLowerCase()
                .replace(/\s+/g, "-")
                .slice(0, 200)}`}
            >
              <GatsbyImage
                image={integration.image.asset.gatsbyImageData}
                alt={integration.image.asset.originalFilename}
                objectFit="contain"
              />
            </Link>
          ))}
        </div>
        <p>Go to Integrations page to find out more</p>
        <MyLink {...integrationsFeed.button} />
      </div>
    </div>
  )
}

export default IntegrationsFeed
