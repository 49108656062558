import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlocksContent from "../blocksContent"
import FeatureCTA from "./featureCTA"
import GrowthSvg from "../growthSVG"
import { ReactComponent as Svg } from "./right_svg.svg"

import "./productFeatures.sass"

const ProductFeatures = () => {
  const {
    sanityProductsPage: { prodcutFeatureSection: data },
  } = useStaticQuery(graphql`
    {
      sanityProductsPage {
        prodcutFeatureSection {
          description {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          featureCtas {
            _key
            title
            description
            featureDropdowns {
              title
              description {
                _rawColumnContent(resolveReferences: { maxDepth: 5 })
              }
            }
            featureImage {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="product-features-section">
      <div className="svgMax">
        {/* <GrowthSvg
          width="180px"
          top="62%"
          right="15px"
          start={100}
          end={200}
          zIndex="1"
        >
          <Svg />
        </GrowthSvg> */}
      </div>
      <div className="container">
        <div className="description">
          <BlocksContent blocks={data.description._rawColumnContent} />
        </div>

        <div className="feature-cta-wrapper">
          {data.featureCtas.map(featureCta => (
            <FeatureCTA key={featureCta._key} cta={featureCta} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductFeatures
