import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./productHero.sass"
import BackgroundVideo from "../shared/backgroundVideo"
import { Container } from "@util/standard"

const CustomerVideo = () => {
  const { sanityProductsPage: data } = useStaticQuery(graphql`
    {
      sanityProductsPage {
        animation {
          asset {
            assetId
            filename
            _type
            playbackId
            status
            thumbTime
          }
        }
        animationTitle
        video {
          url
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const backgroundURL = `https://player.vimeo.com/video/${data.video.url}?background=1&autoplay=1&loop=1&byline=0&title=0`

  return (
    <div className="product-hero-animation">
      <h2>{data.animationTitle}</h2>

      <Container width="100%" height="600px" position="relative">
        <BackgroundVideo
          url={backgroundURL}
          background
          volume={false}
          isVisible
          width="100%"
          height="100%"
        />
      </Container>
    </div>
  )
}

export default CustomerVideo
