import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Collapse } from "antd"
import BlocksContent from "../blocksContent"
import "./featureCTA.sass"

const { Panel } = Collapse

const FeatureCTA = ({ cta }) => {
  return (
    <div className="product-feature-cta">
      <div className="content">
        {cta.title && <h3>{cta.title}</h3>}
        {cta.description && <p>{cta.description}</p>}

        <Collapse bordered={false} defaultActiveKey={["1"]}>
          {cta.featureDropdowns.map((dropdown, index) => (
            <Panel key={index + 1} header={dropdown.title}>
              <BlocksContent blocks={dropdown.description._rawColumnContent} />
            </Panel>
          ))}
        </Collapse>
      </div>

      <div className="image">
        <GatsbyImage
          image={cta.featureImage.asset.gatsbyImageData}
          alt={cta.featureImage.asset.originalFilename}
        />
      </div>
    </div>
  )
}

export default FeatureCTA
