import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HeroHeader from "../heroHeader"
import "./productHero.sass"

const Header = () => {
  const {
    sanityProductsPage: { heroHanner },
  } = useStaticQuery(graphql`
    {
      sanityProductsPage {
        heroHanner {
          waveColor
          subContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          mainContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)

  return (
    <div className="product-hero">
      <HeroHeader data={heroHanner} />
    </div>
  )
}

export default Header
