import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { IoIosArrowForward } from "react-icons/io"
// import MyLink from "../myLink"

import "./productFeatureGrid.sass"
import Link from "@components/shared/link"

interface Props {
  isPractiseArea: boolean
}

const ProductFeatures = (props: Props) => {
  const {
    sanityProductsPage: { productFeatureGrid: data },
    sanityYourFirmPage: { practiceAreas },
  } = useStaticQuery(graphql`
    {
      sanityProductsPage {
        productFeatureGrid {
          title
          pageLinks {
            _key
            url
            newwindow
            linktext
            internallink
          }
        }
      }
      sanityYourFirmPage {
        practiceAreas {
          paGrid {
            paTitle
            paButton {
              ...sanityLink
            }
          }
          paTitle
        }
      }
    }
  `)

  const { isPractiseArea } = props

  return (
    <div className="product-features-grid-wrapper">
      <div className="container">
        <h3>{isPractiseArea ? practiceAreas.paTitle : data.title}</h3>
        <div className="featuresGrid">
          {isPractiseArea
            ? practiceAreas.paGrid.map(link => {
                return (
                  <div className="featureLink">
                    {/* <MyLink key={link._key} {...link} url={`${link.url}`} /> */}
                    <Link
                      linktext={link.paTitle}
                      url={`/practice-areas${link.paButton.url}`}
                      internallink
                    />

                    <IoIosArrowForward />
                  </div>
                )
              })
            : data.pageLinks.map(link => {
                return (
                  <div className="featureLink">
                    {/* <MyLink key={link._key} {...link} url={`${link.url}`} /> */}
                    <Link
                      url={link.url}
                      linktext={link.linktext}
                      internallink
                    />
                    <IoIosArrowForward />
                  </div>
                )
              })}
        </div>
      </div>
    </div>
  )
}

export default ProductFeatures
