import React from "react"
import Layout from "../components/layout"
import SEO from "../components/product/seo"
import HeroVideo from "../components/product/video"
import HeroBanner from "../components/product/header"
import ProductFeatures from "../components/product/productFeatures"
import ProductFeatureCTA from "../components/product/colourBlockCta"
import ProductFeatureGrid from "../components/product/productFeatureGrid"
import IntegrationsFeed from "../components/product/integrationsFeed"
import BlogsFeed from "../components/product/blogsFeed"
import "./product.sass"

const Product = () => {
  return (
    <Layout>
      <SEO />
      <div id="product_page">
        <HeroBanner className="hero-banner-no-margin" />
        <HeroVideo />
        <ProductFeatures />
        <ProductFeatureCTA />
        <ProductFeatureGrid />
        <IntegrationsFeed />
        <BlogsFeed />
      </div>
    </Layout>
  )
}

export default Product
