import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlocksContent from "../blocksContent"
import FeatureCTA from "./featureCTA"
import "./colourBlockCta.sass"

const ProductFeatures = () => {
  const {
    sanityProductsPage: { productFeaturesCta: data },
  } = useStaticQuery(graphql`
    {
      sanityProductsPage {
        productFeaturesCta {
          _key
          title
          description
          featureDropdowns {
            title
            description {
              _rawColumnContent(resolveReferences: { maxDepth: 5 })
            }
          }
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)

  return (
    <div className="product-colour-features-section">
      <div className="container">
        <FeatureCTA key={data._key} cta={data} />
      </div>
    </div>
  )
}

export default ProductFeatures
